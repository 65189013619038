import { Dispatch, SetStateAction } from 'react';
import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { APIFetch } from '../../../../services/api';
import { useQuestaoProjetoService } from '../../../../services/api-services/questaoProjeto';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export interface TExcluirQuestaoProjeto {
  currentAction: [ActionType, Dispatch<SetStateAction<ActionType>>];
}

export const ExcluirQuestaoProjeto: React.FC<TExcluirQuestaoProjeto> = ({ currentAction }) => {
  const auth = useAuthContext();
  const questaoProjetoService = useQuestaoProjetoService(APIFetch(auth));
  const [action, setAction] = currentAction;

  const handleExcluir = () => {

    setAction({
      ...action,
      action: "loading"
    });

    questaoProjetoService
      .questaoProjeto.excluirQuestaoProjeto(action.id)
      .then((response) => {

        action.promisse && action.promisse({});

        setAction({
          action: 'sucesso',
          id: 0,
          message: 'Questão foi removida do projeto com sucesso!',
          loading: true,
        });

      })
      .catch((error) => {
        setAction({
          action: 'erro',
          id: 0,
          message: error.response.data.message,
        });
      });
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        setAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={['PERMISSION_GERENCIAR_PROJETOS']}
      roles={['ROLE_GERENTE', 'ROLE_ADMIN']}>
      <ConfirmationModal
        action={currentAction}
        message={`Tem certeza se deseja remover a questão com id #${action.id} do projeto ?`}
        title={'Remover questão do projeto'}
        onOptionNot={() =>
          setAction({
            action: '',
            id: 0,
          })
        }
        onOptionYes={handleExcluir}
      />
    </ModalAuthRoute>
  );
};
