import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { APIFetch } from '../../../../services/api';
import { useOrgaoService } from '../../../../services/api-services/orgao';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const ExcluirOrgao: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const orgaoService = useOrgaoService(APIFetch(auth));

  const handleExcluir = () => {
    orgaoService
      .excluirOrgao(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          id: 0,
          action: 'sucesso',
          message: 'Orgão foi excluído com sucesso!',
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: error.response.data.message,
        });
      });
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={['PERMISSION_GERENCIAR_QUESTOES']}
      roles={['ROLE_GERENTE', 'ROLE_ADMIN']}>
      <ConfirmationModal
        action={context.currentActionState}
        message={`Tem certeza se deseja excluir o orgão com id #${context.currentAction.id} ?`}
        title={'Excluir orgão'}
        onOptionNot={() =>
          context.changeAction({
            action: '',
            id: 0,
          })
        }
        onOptionYes={handleExcluir}
      />
    </ModalAuthRoute>
  );
};
