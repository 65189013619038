import { FormEvent, Fragment, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ModalAuthRoute } from "../../../../components/router/ModalAuthRoute";
import { AsyncSelect } from "../../../../components/select/async";
import { APIFetch } from "../../../../services/api";
import { useAutorService } from "../../../../services/api-services/autores";
import {
  ComentarioItem,
  useComentarioService
} from "../../../../services/api-services/comentarios";
import { usePerfilQuestoesService } from "../../../../services/api-services/perfilQuestoes";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ClassificacaoContent, ClassificacaoFooter } from "./styles";

export const ClassificarComentarios: React.FC<{
  ids: ItemDataSelect<ComentarioItem>[];
}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    perfilQuestoesService: usePerfilQuestoesService(APIFetch(auth)),
    autorService: useAutorService(APIFetch(auth)),
    comentarioService: useComentarioService(APIFetch(auth)),
  };

  const [autor, setAutor] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const [perfilQuestao, setPerfilQuestao] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.comentarioService

      .classificar(
        props.ids.map((ele) => ele.id_item),
        autor.id,
        perfilQuestao.id
      )
      .then(() => {
        context.changeAction({
          action: "",
          id: 0,
          loading: true,
        });
        alert("Comentários classificados com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const Footer = () => {
    return (
      <ClassificacaoFooter>
        {perfilQuestao.value && autor.value && (
          <Fragment>
            <p className={"p-style"}>
              Você selecionou a classificação{" "}
              <small>{`(${perfilQuestao.value} - ${autor.value})`}</small>
            </p>
            <ButtonBottomLess
              className="save"
              title="Salvar"
              icon={IoMdCheckmark}
              type={"submit"}
            />
          </Fragment>
        )}
      </ClassificacaoFooter>
    );
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: "erro",
          message: "Você não tem permissão para acessar a funcionalidade!",
        });
      }}
      permissions={["PERMISSION_GERENCIAR_COMENTARIOS"]}
      roles={["ROLE_GERENTE", "ROLE_ADMIN"]}
    >
      <form onSubmit={onSubmit}>
        <Modal
          action={context.currentActionState}
          title={
            props.ids.length > 1
              ? `Classificar -  ${props.ids.length} comentários`
              : `Classificar -  ${props.ids.length} comentário`
          }
          options={Footer()}
        >
          <ClassificacaoContent>
            <div className="search-prova">
              <p className={"p-style"}>
                Selecione um perfil de questão e um autor para classificação
              </p>
            </div>
            <div className="filters">
              <AsyncSelect
                className="perfil field-group"
                showSelectItem={true}
                label="Perfil de questão"
                selectItem={perfilQuestao}
                onAddFilterItem={(item) => setPerfilQuestao(item)}
                getDataAPI={(page, value) =>
                  services.perfilQuestoesService.listarPerfils(page, 30, value)
                }
              />
              <AsyncSelect
                className="autor field-group"
                label="Autor"
                selectItem={autor}
                showSelectItem={true}
                onAddFilterItem={(item) => setAutor(item)}
                getDataAPI={(page, value) =>
                  services.autorService.listarAutores(page, 30, value)
                }
              />
            </div>
          </ClassificacaoContent>
        </Modal>
      </form>
    </ModalAuthRoute>
  );
};
