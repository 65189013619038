import { ConfirmationModal } from "../../../../components/modals/confirmation-modal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ModalAuthRoute } from "../../../../components/router/ModalAuthRoute";
import { APIFetch } from "../../../../services/api";
import { useTagService } from "../../../../services/api-services/tag";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";

export const ExcluirTag: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const tagService = useTagService(APIFetch(auth));
  const context = useGerenciarContext();

  const handleExcluir = () => {
    tagService
      .excluirTag(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          action: "sucesso",
          id: 0,
          message: "Tag foi excluído com sucesso!",
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          action: "erro",
          id: 0,
          message: error.response.data.message,
        });
      });
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: "erro",
          message: "Você não tem permissão para acessar a funcionalidade!",
        });
      }}
      permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
      roles={["ROLE_GERENTE", "ROLE_ADMIN"]}
    >
      <ConfirmationModal
        action={context.currentActionState}
        message={`Tem certeza se deseja excluir o tag com id #${context.currentAction.id} ?`}
        title={"Excluir tag"}
        onOptionNot={() =>
          context.changeAction({
            action: "",
            id: 0,
          })
        }
        onOptionYes={handleExcluir}
      />
    </ModalAuthRoute>
  );
};
