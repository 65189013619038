import { Fragment, useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import { APIFetch } from "../../services/api";
import {
  DashboardEstatisticas,
  useDashboard,
} from "../../services/api-services/dashboard";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { GerenciarProvas } from "../GerenciarProvas";
import {
  Circle,
  ColunaPrimaria,
  ColunaSecundaria,
  Group,
  ItemLegenda,
  MainDashboard,
} from "./styles";




export const Dashboard = () => {

  const auth = useAuthContext();

  const renderView = () => {

    if (auth.user.roles.includes("ROLE_CLASSIFICADOR")) {

      return <GerenciarProvas />

    } else {

      return <DashboardComponent />

    }

  }

  return renderView();
}

export const DashboardComponent = () => {

  const auth = useAuthContext();
  const dashboardAPI = useDashboard(APIFetch(auth));
  const [estatisticas, setEstatisticas] = useState<DashboardEstatisticas>({
    registered_comments: 0,
    registered_exams: 0,
    registered_questions: 0,
    registered_questions_no_classification: 0,
    registered_questions_no_comments: 0,
    registered_questions_no_exams: 0,
    registered_questions_outdated: 0,
    registered_questions_pendency: 0,
    registered_questions_pending: 0,
    registered_questions_validated: 0,
  });

  useEffect(() => {
    dashboardAPI.dashboard().then((value) => {
      setEstatisticas(value);
    });
  }, []);

  const data_1: Data = [
    {
      title: "Sem comentários",
      value: estatisticas.registered_questions_no_comments,
      color: "#ffc7c7",
    },
    {
      title: "Desatualizadas",
      value: estatisticas.registered_questions_outdated,
      color: "#f7c57e",
    },
    {
      title: "Sem provas",
      value: estatisticas.registered_questions_no_exams,
      color: "#d41c15",
    },
    {
      title: "Sem classificação",
      value: estatisticas.registered_questions_no_classification,
      color: "#ff443d",
    },
  ];

  const data_2: Data = [
    {
      title: "Pendentes",
      value: estatisticas.registered_questions_pending,
      color: "#ff9494",
    },
    {
      title: "Validadas",
      value: estatisticas.registered_questions_validated,
      color: "#ff4545",
    },
  ];

  return (
    <Fragment>
      <MainDashboard>
        {!auth.user.roles.includes("ROLE_GESTOR_DO_SISTEMA") && <Fragment>
          <ColunaPrimaria>
            <Group>
              <div className="header">
                <span>Questão</span>
              </div>
              <section>
                <p>Questões criadas</p>
                <h2>{estatisticas.registered_questions}</h2>
              </section>
              <PieChart
                className="chart-1"
                lineWidth={24}
                paddingAngle={2}
                rounded
                data={data_1}
              />
              <div className="legenda-content">
                <div>
                  <ItemLegenda>
                    <Circle color={data_1[2].color} />
                    <p>Sem provas:</p>
                    <b>{estatisticas.registered_questions_no_exams}</b>
                  </ItemLegenda>
                  <ItemLegenda>
                    <Circle color={data_1[1].color} />
                    <p>Desatualizadas:</p>
                    <b>{estatisticas.registered_questions_outdated}</b>
                  </ItemLegenda>
                </div>
                <div>
                  <ItemLegenda>
                    <Circle color={data_1[0].color} />
                    <p>Sem comentários:</p>
                    <b>{estatisticas.registered_questions_no_comments}</b>
                  </ItemLegenda>
                  <ItemLegenda>
                    <Circle color={data_1[3].color} />
                    <p>Sem classificação:</p>
                    <b>{estatisticas.registered_questions_no_classification}</b>
                  </ItemLegenda>
                </div>
              </div>
            </Group>
          </ColunaPrimaria>
          <ColunaSecundaria>
            <div className="row-group">
              <Group className="numbers">
                <section>
                  <p>Provas criadas</p>
                  <h2>{estatisticas.registered_exams}</h2>
                </section>
              </Group>
              <Group className="numbers">
                <section>
                  <p>Comentários realizados</p>
                  <h2>{estatisticas.registered_comments}</h2>
                </section>
              </Group>
            </div>
            <Group>
              <div className="header">
                <span>Status</span>
              </div>
              <PieChart
                className="chart-2"
                lineWidth={14}
                paddingAngle={2}
                rounded
                data={data_2}
              />
              <div className="legenda-content">
                <div>
                  <ItemLegenda>
                    <Circle color={data_2[0].color} />
                    <p>Questões pendentes:</p>
                    <b>{estatisticas.registered_questions_pending}</b>
                  </ItemLegenda>
                  <ItemLegenda>
                    <Circle color={data_2[1].color} />
                    <p>Questões validadas:</p>
                    <b>{estatisticas.registered_questions_validated}</b>
                  </ItemLegenda>
                </div>
                <div></div>
              </div>
            </Group>
          </ColunaSecundaria>
        </Fragment>}
      </MainDashboard>
    </Fragment>
  );
};
