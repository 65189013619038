import { FormEvent, Fragment, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { InputForm } from "../../../../components/input";
import { Modal } from '../../../../components/modals/modal-base';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ItemSelect } from '../../../../components/page/structure/FilterComponent/types';
import { ItemDataSelect } from '../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { AsyncSelect } from '../../../../components/select/async';
import { NormalSelect, TItemSelect } from "../../../../components/select/normal";
import { elegibilidadeClassificacaoQuestao, selectMostrarProvaDisciplina } from "../../../../mocks/data";
import { APIFetch } from '../../../../services/api';
import { useDisciplinaService } from '../../../../services/api-services/disciplina';
import { Questao, useQuestaoService } from '../../../../services/api-services/questao';
import { useTagService } from '../../../../services/api-services/tag';
import { useTemaService } from '../../../../services/api-services/tema';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { ClassificacaoContent, ClassificacaoFooter } from './styles';

export const FormClassificacao: React.FC<{
  ids: ItemDataSelect<Questao>[];
}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    temaService: useTemaService(APIFetch(auth)),
    tagService: useTagService(APIFetch(auth)),
    disciplinaService: useDisciplinaService(APIFetch(auth)),
    questaoService: useQuestaoService(APIFetch(auth)),
  };

  const [tag, setTag] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const [tema, setTema] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const [disciplina, setDisciplina] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });


  const [ativarDiscProva, setAtivarDiscProva] = useState<boolean>();

  const [ativarElegibilidadeClassificacao, setAtivarElegibilidadeClassificacao] = useState<boolean>();

  const [discProva, setDiscProva] = useState<string>("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.questaoService
      .classificar(
        props.ids.map((ele) => ele.id_item),
        ativarDiscProva,
        ativarElegibilidadeClassificacao,
        tema.id !== 0 ? tema.id : undefined,
        disciplina.id !== 0 ? disciplina.id : undefined,
        tag.id !== 0 ? tag.id : undefined,
        discProva
      )
      .then(() => {
        context.changeAction({
          action: '',
          id: 0,
          loading: true,
        });
        alert('Questões foram classificadas com sucesso!');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const apiTemas = (page: number, value: string, param: string) => {
    return services.temaService.listarTemasByDisciplina(page, 30, value, param, false);
  };

  const apiTags = (page: number, value: string, param: string) => {
    return services.tagService.listarTagsByDisciplina(page, 30, value, param, false);
  };

  const onChangeSelecaoComoCondicional = (item: TItemSelect) => {

    let checked: boolean | undefined = undefined;

    if (item.id === 1) {
      checked = true;
    } else if (item.id === 2) {
      checked = false;
    }

    if (item.key === "eligibility_classification") {
      setAtivarElegibilidadeClassificacao(checked);
    } else if (item.key === "exam_disc_active") {
      setAtivarDiscProva(checked);
    }

  }


  const Footer = () => {

    const classificationInfo = [
      ativarDiscProva && (ativarDiscProva ? "Mostrar (disciplina prova)" : "Ocultar (disciplina prova)"),
      ativarElegibilidadeClassificacao && (ativarElegibilidadeClassificacao ? "Elegível a classificação externa" : "Não elegível a classificação externa"),
      discProva,
      disciplina?.value,
      tema?.value,
      tag?.value
    ].filter(Boolean);

    const shouldDisplayFooter = classificationInfo.length > 0;

    return (
      <ClassificacaoFooter>
        {shouldDisplayFooter && (
          <Fragment>
            <p className="p-style">
              Você selecionou a classificação: <br></br><br></br>
              <small>
                {classificationInfo.map((info, index) => (
                  <span key={index}>
                    {info}
                    {index < classificationInfo.length - 1 && " + "}
                  </span>
                ))}
              </small>
            </p>
            <ButtonBottomLess className="save" title="Salvar" icon={IoMdCheckmark} type="submit" />
          </Fragment>
        )}
      </ClassificacaoFooter>
    );
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={['PERMISSION_GERENCIAR_QUESTOES']}
      roles={['ROLE_GERENTE', 'ROLE_ADMIN', "ROLE_CLASSIFICADOR"]}>
      <form onSubmit={onSubmit}>
        <Modal
          action={context.currentActionState}
          title={
            props.ids.length > 1
              ? `Atualização geral de ${props.ids.length} questões`
              : `Atualização geral de ${props.ids.length} questão`
          }
          options={Footer()}>
          <ClassificacaoContent>
            <div className="search-prova">
              <p className={'p-style'}>Preencha um ou mais campos que deseja atualizar nas questões selecionadas(em lote)</p>
            </div>
            <div className="filters">
              <NormalSelect
                minWidth={0}
                className="field-group"
                label="Selecione a elegibilidade de classificação externa (JusQuestões)"
                onSelectCurrentItem={(item) => onChangeSelecaoComoCondicional(item)}
                selectedItems={elegibilidadeClassificacaoQuestao}
              />
              <NormalSelect
                minWidth={0}
                className="field-group"
                label="Selecione a visibilidade (disciplina prova)"
                onSelectCurrentItem={(item) => onChangeSelecaoComoCondicional(item)}
                selectedItems={selectMostrarProvaDisciplina}
              />
              <InputForm
                className="field-group"
                placeholder="Disciplina Prova"
                value={discProva}
                onChange={(value) => setDiscProva(value.currentTarget.value)}
              />
              <AsyncSelect
                className="disciplina field-group"
                showSelectItem={true}
                label="Disciplina"
                selectItem={disciplina}
                onAddFilterItem={(item) => setDisciplina(item)}
                getDataAPI={(page, value) => services.disciplinaService.listarDisciplinas(page, 30, value)}
              />
              <AsyncSelect
                className="tema field-group"
                label="Tema"
                dependency={true}
                selectItem={tema}
                showSelectItem={true}
                onAddFilterItem={(item) => setTema(item)}
                secondaryValue={disciplina}
                getDataAPIParam={apiTemas}
              />
              <AsyncSelect
                className="tag field-group"
                label="TAG"
                dependency={true}
                selectItem={tag}
                showSelectItem={true}
                onAddFilterItem={(item) => setTag(item)}
                secondaryValue={disciplina}
                getDataAPIParam={apiTags}
              />
            </div>
          </ClassificacaoContent>
        </Modal>
      </form>
    </ModalAuthRoute>
  );
};
