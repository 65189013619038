import React from "react";
import { MainApp, MainAppBaseType } from "../../components/page/structure/MainApp";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { JusBqNavbar } from "./Navbar";
import { JusBqSidebar, JusBqSidebarClassificador, JusBqSidebarGestorSistema } from "./Sidebar";



export const BancoQuestoesApp: React.FC<MainAppBaseType> = ({
  component,
}: MainAppBaseType) => {

  const { user } = useAuthContext();
  const { roles } = user;

  const renderTipo = () => {

    if (roles.includes("ROLE_CLASSIFICADOR")) {

      return JusBqSidebarClassificador

    } else if (roles.includes("ROLE_GESTOR_DO_SISTEMA")) {

      return JusBqSidebarGestorSistema;

    } else {

      return JusBqSidebar;

    }


  }

  return (
    <MainApp
      component={component}
      sidebar={{
        sidebarValue: {
          appName: "Banco de questões",
          companyName: "Juspodivm",
        },
        sidebarType: renderTipo(),
      }}
      navbar={{
        navbarType: JusBqNavbar,
      }}
    />
  );
};
