import { FormEvent, Fragment, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { IoMdCheckmark } from "react-icons/io";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { InputIcon } from "../../../../components/input/input-icon";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
  ListCard,
  PaginationContent
} from "../../../../components/page/structure/ListComponent/styles";
import { Pagination } from "../../../../components/pagination";
import { ModalAuthRoute } from "../../../../components/router/ModalAuthRoute";
import { APIFetch } from "../../../../services/api";
import { useProvaService } from "../../../../services/api-services/prova";
import {
  Questao,
  useQuestaoService
} from "../../../../services/api-services/questao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import {
  AnexarProvaContent,
  ProvaFooterContent,
  ProvaItemContent
} from "./styles";

export const FormProva: React.FC<{
  ids: ItemDataSelect<Questao>[];
}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    provaService: useProvaService(APIFetch(auth)),
    questaoService: useQuestaoService(APIFetch(auth)),
  };

  const [search_key, setSearch_key] = useState("");

  const [totalPages, setTotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const loadingState = useState(false);

  const loading = loadingState[0];
  const setLoading = loadingState[1];

  const provaState = useState<Array<ItemSelect>>([]);
  const setProvas = provaState[1];

  const [selectedProva, setSelectedProva] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  useEffect(() => {
    if (loading) {
      services.provaService
        .listarProvas(currentPage - 1, 5, search_key)
        .then((provas) => {
          setProvas(
            provas.content.map((value) => {
              return value;
            })
          );

          setTotalPages(provas.total_number_pages);
          setLoading(false);
        });
    }
  }, [loading]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.questaoService
      .adicionarProva(
        props.ids.map((ele) => ele.id_item),
        selectedProva.id
      )
      .then(() => {
        context.changeAction({
          id: 0,
          action: "",
          loading: true,
        });
        alert(
          "Questões com a prova selecionada foram atualizadas com sucesso!"
        );
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const Footer = () => {
    return (
      <ProvaFooterContent>
        {selectedProva.id != 0 && (
          <Fragment>
            <p className={"p-style"}>
              Você selecionou <small>{selectedProva.value}</small>
            </p>
            <ButtonBottomLess
              className="save"
              title="Salvar"
              icon={IoMdCheckmark}
              type={"submit"}
            />
          </Fragment>
        )}
      </ProvaFooterContent>
    );
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: "erro",
          message: "Você não tem permissão para acessar a funcionalidade!",
        });
      }}
      permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
      roles={["ROLE_GERENTE", "ROLE_ADMIN"]}
    >
      <form onSubmit={onSubmit}>
        <Modal
          action={context.currentActionState}
          title={
            props.ids.length > 1
              ? `Adicionar prova -  ${props.ids.length} questões`
              : `Adicionar prova -  ${props.ids.length} questão`
          }
          options={Footer()}
        >
          <AnexarProvaContent>
            <div className="search-prova">
              <p className={"p-style"}>
                Pesquise e selecione a prova que deseja anexar
              </p>
              <InputIcon
                onChangeValue={(value) => {
                  setSearch_key(value);
                  setLoading(true);
                }}
                onClickSearch={() => { }}
                icon={FaSearch}
                className={"search"}
                placeholder={"Pesquisar por orgão,banca ou cargo..."}
              />
            </div>
            <ListCard className="list-prova">
              {provaState[0].map((item, index) => {
                return (
                  <ProvaItemContent
                    onClick={() => {
                      setSelectedProva(item);
                    }}
                  >
                    <p>{item.value}</p>
                    {item.id == selectedProva.id && (
                      <HiOutlineCheckCircle
                        size={24}
                        color={"var(--color-primary-darking)"}
                      />
                    )}
                  </ProvaItemContent>
                );
              })}
            </ListCard>
            <PaginationContent className="pagination">
              <Pagination
                totalNumberPages={totalPages}
                isInitialPage={false}
                onCurrentPage={(current) => {
                  setCurrentPage(current);
                  setLoading(true);
                }}
              />
            </PaginationContent>
          </AnexarProvaContent>
        </Modal>
      </form>
    </ModalAuthRoute>
  );
};
