import React from "react";
import { BiBook, BiImport } from "react-icons/bi";
import { BsCardChecklist, BsFolder } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import { TfiWrite } from "react-icons/tfi";
import { VscCircleOutline } from "react-icons/vsc";
import {
  Sidebar,
  SidebarBaseType,
} from "../../../components/page/structure/Sidebar";
import { SubItemSidebarProps } from "../../../components/page/structure/Sidebar/SubItem";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";



export const JusBqSidebar: React.FC<SidebarBaseType> = ({
  appName,
  companyName,
}) => {
  const auth = useAuthContext();



  const otherOptions: SubItemSidebarProps[] = [
    {
      title: "Carreiras",
      icon: VscCircleOutline,
      path: "/gerenciar-carreiras",
    },
    {
      title: "Bancas",
      icon: VscCircleOutline,
      path: "/gerenciar-bancas",
    },
    {
      title: "Cargos",
      icon: VscCircleOutline,
      path: "/gerenciar-cargos",
    },
    {
      title: "Orgãos",
      icon: VscCircleOutline,
      path: "/gerenciar-orgaos",
    },
    {
      title: "Perfil questões",
      icon: VscCircleOutline,
      path: "/gerenciar-perfil-questoes",
    },
    {
      title: "Tipos de Leis",
      icon: VscCircleOutline,
      path: "/gerenciar-tipo-leis",
    },
    {
      title: "Autores",
      icon: VscCircleOutline,
      path: "/gerenciar-autores",
    },
  ];

  let otherOptionsAdmin = [
    ...otherOptions,
    {
      title: "Usuários",
      icon: VscCircleOutline,
      path: "/gerenciar-usuarios",
    },
  ];

  const renderOtherOptions = (): SubItemSidebarProps[] => {
    if (auth.user.roles.includes("ROLE_ADMIN")) {
      return otherOptionsAdmin;
    } else {
      return otherOptions;
    }
  };

  return (
    <Sidebar
      mainOptions={[
        {
          title: "Dashboard",
          icon: MdDashboard,
          path: "/",
        },
        {
          title: "Projetos de questões",
          icon: BsFolder,
          subItems: [
            {
              title: "Listar projeto de questões",
              icon: VscCircleOutline,
              path: "/projetos",
            },
            {
              title: "Criar um projeto de questões",
              icon: VscCircleOutline,
              path: "/projeto",
            },
            {
              title: "Gerenciar projeto de questões",
              icon: VscCircleOutline,
              path: "/gerenciar-projetos",
            },
          ],
        },
        {
          title: "Questões",
          icon: RiQuestionnaireLine,
          subItems: [
            {
              title: "Criar questão",
              icon: VscCircleOutline,
              path: "/questao",
            },
            {
              title: "Importar questões",
              icon: BiImport,
              path: "/questao/importar",
            },
            {
              title: "Gerenciar questões",
              icon: VscCircleOutline,
              path: "/gerenciar-questoes",
            },
            {
              title: "Gerenciar comentários",
              icon: VscCircleOutline,
              path: "/gerenciar-comentarios",
            },
          ],
        },
        {
          title: "Provas",
          icon: BsCardChecklist,
          subItems: [
            {
              title: "Gerenciar provas",
              icon: VscCircleOutline,
              path: "/gerenciar-provas",
            },
          ],
        },
        {
          title: "Disciplinas",
          icon: BiBook,
          subItems: [
            {
              title: "Gerenciar disciplinas",
              icon: VscCircleOutline,
              path: "/gerenciar-disciplinas",
            },
          ],
        },
        {
          title: "JusQuestões",
          icon: TfiWrite,
          subItems: [
            {
              title: "Moderar comentários",
              icon: VscCircleOutline,
              path: "/moderar-comentarios",
            },
            {
              title: "Indicações (Fonte Resposta)",
              icon: VscCircleOutline,
              path: "/indicacao-fonte-resposta",
            },
          ],
        },
      ]}
      otherOptions={renderOtherOptions()}
      appName={appName}
      companyName={companyName}
    />
  );
};


export const JusBqSidebarClassificador: React.FC<SidebarBaseType> = ({
  appName,
  companyName,
}) => {

  return (
    <Sidebar
      mainOptions={[
        {
          title: "Início",
          icon: BsCardChecklist,
          path: "/",
        },
        {
          title: "Questões",
          icon: RiQuestionnaireLine,
          subItems: [
            {
              title: "Listar questões",
              icon: VscCircleOutline,
              path: "/gerenciar-questoes",
            }
          ],
        }
      ]}
      otherOptions={[]}
      appName={appName}
      companyName={companyName}
    />
  );
};


export const JusBqSidebarGestorSistema: React.FC<SidebarBaseType> = ({
  appName,
  companyName,
}) => {

  return (
    <Sidebar
      mainOptions={[
        {
          title: "Início",
          icon: BsCardChecklist,
          path: "/",
        },
        {
          title: "Usuários",
          icon: RiQuestionnaireLine,
          subItems: [
            {
              title: "Gerenciar Usuários",
              icon: VscCircleOutline,
              path: "/gerenciar-usuarios",
            },
          ],
        }
      ]}
      otherOptions={[]}
      appName={appName}
      companyName={companyName}
    />
  );
};
