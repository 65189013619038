import { AsyncSelect } from "jusadmin-ui-kit";
import { FormEvent, Fragment, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ModalAuthRoute } from "../../../../components/router/ModalAuthRoute";
import { APIFetch } from "../../../../services/api";
import { useCarreiraService } from "../../../../services/api-services/carreira";
import { ProvaDetailsCount, useProvaService } from "../../../../services/api-services/prova";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import {
  AtribuirCarreiraLoteContent,
  AtribuirCarreiraLoteFooter,
} from "./styles";

export const FormAtribuirCarreiraEmLote: React.FC<{
  ids: ItemDataSelect<ProvaDetailsCount>[];
}> = (props) => {

  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    carreiraService: useCarreiraService(APIFetch(auth)),
    provaService: useProvaService(APIFetch(auth)),
  };

  const [carreira, setCarreira] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.provaService
      .atribuirCarreiraEmLote(
        props.ids.map((ele) => ele.id_item),
        carreira.id as unknown as number
      )
      .then(() => {
        context.changeAction({
          action: "",
          id: 0,
          loading: true,
        });
        alert("Provas foram atualizadas com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const Footer = () => {
    return (
      <AtribuirCarreiraLoteFooter>
        {carreira.value && (
          <Fragment>
            <p className={"p-style"}>
              Você selecionou a carreira
              <small>{`(${carreira.value})`}</small>
            </p>
            <ButtonBottomLess
              className="save"
              title="Alterar carreira"
              icon={IoMdCheckmark}
              type={"submit"}
            />
          </Fragment>
        )}
      </AtribuirCarreiraLoteFooter>
    );
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: "erro",
          message: "Você não tem permissão para acessar a funcionalidade!",
        });
      }}
      permissions={[]}
      roles={["ROLE_GERENTE", "ROLE_ADMIN"]}
    >
      <form onSubmit={onSubmit}>
        <Modal
          action={context.currentActionState}
          title={
            props.ids.length > 1
              ? `Alterar carreira de (${props.ids.length}) provas`
              : `Alterar carreira de (${props.ids.length}) prova`
          }
          options={Footer()}
        >
          <AtribuirCarreiraLoteContent>
            <div>
              <p className={"p-style"}>Selecione uma carreira:</p>
            </div>
            <div className="filters">
              <AsyncSelect
                className="carreira field-group"
                showSelectItem={true}
                label="Carreira"
                selectItem={carreira}
                onAddFilterItem={(item) => setCarreira(item)}
                getDataAPI={(page, value) =>
                  services.carreiraService.listarCarreiras(page, 30, value)
                }
              />
            </div>
          </AtribuirCarreiraLoteContent>
        </Modal>
      </form>
    </ModalAuthRoute>
  );
};
