import { FormEvent, Fragment, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { Modal } from '../../../../components/modals/modal-base';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ItemSelect } from '../../../../components/page/structure/FilterComponent/types';
import { ItemDataSelect } from '../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { SelectOption } from '../../../../components/select/option';
import { validacaoQuestao } from '../../../../mocks/data';
import { APIFetch } from '../../../../services/api';
import { Questao, useQuestaoService } from '../../../../services/api-services/questao';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { ValidacaoQuestaoContent, ValidacaoQuestaoFooter } from './styles';

export const FormValidacaoQuestao: React.FC<{
  ids: ItemDataSelect<Questao>[];
}> = (props) => {

  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    questaoService: useQuestaoService(APIFetch(auth)),
  };

  const [validacao, setValidacao] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.questaoService
      .alterarStatusValidacao(
        props.ids.map((ele) => ele.id_item),
        validacao.value
      )
      .then(() => {
        context.changeAction({
          action: '',
          id: 0,
          loading: true,
        });
        alert('Questões foram alteradas com sucesso!');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const Footer = () => {
    return (
      <ValidacaoQuestaoFooter>
        {validacao.value && (
          <Fragment>
            <p className={'p-style'}>
              Você selecionou o status
              <small>{`(${validacao.value})`}</small>
            </p>
            <ButtonBottomLess className="save" title="Alterar status" icon={IoMdCheckmark} type={'submit'} />
          </Fragment>
        )}
      </ValidacaoQuestaoFooter>
    );
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={[]}
      roles={['ROLE_GERENTE', 'ROLE_ADMIN']}>
      <form onSubmit={onSubmit}>
        <Modal
          action={context.currentActionState}
          title={
            props.ids.length > 1
              ? `Alterar status de (${props.ids.length}) questões`
              : `Alterar status de (${props.ids.length}) questão`
          }
          options={Footer()}>
          <ValidacaoQuestaoContent>
            <div className="search-prova">
              <p className={'p-style'}>Selecione um status de validação:</p>
            </div>
            <div className="filters">
              <SelectOption
                className="validacao field-group"
                label="Status"
                onAddFilterItem={(item) => setValidacao(item)}
                getData={validacaoQuestao}
              />
            </div>
          </ValidacaoQuestaoContent>
        </Modal>
      </form>
    </ModalAuthRoute>
  );
};
