import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { APIFetch } from '../../../../services/api';
import { useQuestaoService } from '../../../../services/api-services/questao';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

interface ExclusaoLoteQuestaoStateProps {
  questoes: Set<number>
}

export const ExclusaoLoteQuestao = () => {

  const auth = useAuthContext();
  const context = useGerenciarContext();
  const questaoService = useQuestaoService(APIFetch(auth));

  const dataState: ExclusaoLoteQuestaoStateProps | undefined = context
    .currentAction.object as unknown as ExclusaoLoteQuestaoStateProps;

  const handleExcluir = () => {
    questaoService
      .excluirQuestaoEmLote(dataState ? dataState.questoes : new Set())
      .then((response) => {

        context.changeAction({
          action: 'pos-exclusao-questoes',
          id: 0,
          loading: true,
          object: {
            questoes: response
          }
        });

      })
      .catch((error) => {

        context.changeAction({
          action: 'erro',
          id: 0,
          message: error.response.data.message,
        });

      });
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={['PERMISSION_GERENCIAR_QUESTOES']}
      roles={['ROLE_GERENTE', 'ROLE_ADMIN']}>
      <ConfirmationModal
        action={context.currentActionState}
        message={`Tem certeza se deseja excluir as questões selecionadas?`}
        title={'Excluir questões'}
        onOptionNot={() =>
          context.changeAction({
            action: '',
            id: 0,
          })
        }
        onOptionYes={handleExcluir}
      />
    </ModalAuthRoute>
  );
};
