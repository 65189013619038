import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { MesclarEntidades } from "../../components/app/mesclar-entidade";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { orgaoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { Orgao, useOrgaoService } from "../../services/api-services/orgao";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarOrgao } from "./forms/criar-orgao";
import { ExcluirOrgao } from "./forms/excluir-orgao";
import { OrgaoHeader } from "./views/row-orgao";

const GerenciarOrgaosComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const orgaoService = useOrgaoService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const OrgaoState = useState<Array<ItemDataSelect<Orgao>>>([]);
  const setOrgaos = OrgaoState[1];

  const selectedEntitiesAction = useSelectedDataAction<Orgao>({
    state: OrgaoState,
  });

  useEffect(() => {
    if (context.loading) {
      orgaoService
        .listagemOrgaos(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((orgaos) => {
          setOrgaos(
            orgaos.content.map((value: Orgao) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(orgaos.paginationData);
          context.toPage(orgaos.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo Orgão"}
          className={"Orgao-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar-orgao" });
          }}
        />
      </Fragment>
    );
  };

  const onFinishMesclagemEvent = () => {
    selectedEntitiesAction.removeAll();
  }

  const modals = () => {
    switch (context.currentAction.action) {
      case "mesclar-orgao": {
        return (
          <MesclarEntidades
            tituloUI="Mesclagem de orgãos"
            onFinishMesclagemEvent={onFinishMesclagemEvent}
            onMesclarEntidadesAPI={(id, orgaos) =>
              orgaoService.mesclarOrgaos(id, orgaos)
            }
            permissions={["ROLE_GERENTE", "ROLE_ADMIN"]}
          />
        );
      }
      case "excluir": {
        return <ExcluirOrgao />;
      }
      case "criar-orgao": {
        return <CriarOrgao isModal />;
      }
      case "editar-orgao": {
        return <CriarOrgao isModal />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<Orgao>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={OrgaoState}
        page={{
          name: "Listagem de orgãos",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do orgão",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: orgaoOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [
              <div>Ações</div>,
              <div>ID</div>,
              <div>Imagem</div>,
              <div>Nome</div>,
              <div>Sigla</div>
            ],
            dataColumn: OrgaoHeader(selectedEntitiesAction).header,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarOrgaos = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarOrgaosComponent />
    </GerenciarBaseProvider>
  );
};
